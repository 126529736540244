import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ToastService} from '../misc/toast.service';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(
    private http: HttpClient,
    private toast: ToastService,
  ) { }

  create(request_body) {
    return new Promise(resolve => {
      this.http.post(`${environment.auth_url}/form/create`, request_body).pipe(
      ).subscribe(res => {
        resolve(res);
      });
    });
  }

  list() {
    return new Promise(resolve => {
      this.http.get(`${environment.auth_url}/form`).subscribe(res => {
        resolve(res);
      });
    });
  }

}
